import { EnvConfig, EnvProvider, IntlProvider, QueryProvider, ThemeProvider } from '@providers';
import { CustomerProvider } from 'providers/CustomerProvider';
import { PropsWithChildren } from 'react';

export function GlobalProvider(props: PropsWithChildren<EnvConfig>) {
  return (
    <QueryProvider>
      <CustomerProvider>
        <EnvProvider env={props}>
          <ThemeProvider>
            <IntlProvider>{props.children}</IntlProvider>
          </ThemeProvider>
        </EnvProvider>
      </CustomerProvider>
    </QueryProvider>
  );
}
