import { enUS } from './en-US';

export const ptBr: typeof enUS = {
  breadcrumb: {
    informationCenter: {
      title: 'Central de informação',
    },
    customerList: {
      title: 'Lista de clientes',
    },
    chainManagement: {
      title: 'Gerenciamento de redes',
    },
    kamList: {
      title: 'Lista de clientes de KAMs',
    },
    kamCustomerList: {
      title: 'Lista de clientes do KAM',
    },
    kamCustomerAddChain: {
      title: 'Adicionar redes',
    },
  },
  pages: {
    customerList: {
      title: 'Qual lista você gostaria de gerenciar?',
      featureCards: {
        kam: {
          title: 'KAM',
          description: 'Alterar lista de clientes de KAMs',
        },
      },
    },
    kamList: {
      title: 'Lista de clientes de KAMs',
      subtitle: 'Gerenciar lista de clientes KAMs',
      selectKAM: 'Selecionar KAM',
      searchPlaceholder: 'Encontre KAM por nome ou email',
      employeeEmail: 'Email',
      oneResultFound: ' resultado encontrado',
      resultsFound: ' resultados encontrados',
      loadingTitle: 'Loading',
      loadingText: "We're almost there...",
    },
    kamCustomerList: {
      title: 'Lista de clientes do KAM',
      employeeEmail: 'Email',
      employeeIdDescription: 'ID do Empregado',
      chainTable: {
        title: 'Redes associadas',
        chainName: 'Nome da rede',
        rowsPerPage: 'Registros por pagina',
        paginationCounter: 'de',
        loadingMessage: 'Carregando redes...',
        emptyMessage: 'Nenhuma rede adicionada',
        btnAddChain: 'Adicionar rede',
        searchPlaceHolder: 'Pesquisar por nome da rede',
        deleteButtonTooltip: 'Excluir rede',
      },
      chainAddChainList: {
        title: 'Adicionar redes',
        table: {
          name: 'Nome da rede',
          regions: 'Regiões',
          all: 'Todas',
          id: 'Identificação',
          rowsPerPage: 'Registros por pagina',
          paginationCounter: 'de',
          loadingMessage: 'Carregando redes...',
          emptyMessage: 'Rede não encontrada',
          searchPlaceHolder: 'Buscar por nome',
          buttonAdd: 'Adicionar',
        },
        btnContinue: 'Continuar',
        btnCancel: 'Cancelar',
        chainSelected: 'rede selecionada.',
        chainSelectedMultiple: 'redes selecionadas.',
        stepsDisplay: {
          step1: 'Selecionar redes',
          step2: 'Selecionar regiões',
        },
        onSuccessToastMessage: 'Rede adicionada com sucesso',
        onSuccessToastMessageMultiple: 'Redes adicionadas com sucesso',
        onErrorToastMessage: 'Não foi possível adicionar a rede. Tente novamente.',
      },
      chainAddRegionsList: {
        regionSelectedSingle: 'região selecionada',
        regionSelectedMultiple: 'regiões selecionadas',
        for: 'para',
        chainSingle: 'rede.',
        chainMultiple: 'redes.',
        onSuccessToastMessageSingle: 'região adicionada com sucesso para',
        onSuccessToastMessageMultiple: 'regiões adicionadas com sucesso para',
        onErrorToastMessage: 'Não foi possível adicionar a rede. Tente novamente.',
      },
      chainDeleteChain: {
        title: 'Tem certeza de que deseja remover esta rede da lista de {name}?',
        nameToRemove: 'Nome: {name}',
        btnContinue: 'Remover',
        btnCancel: 'Cancelar',
        onSuccessToastMessage: 'Rede removida com sucesso',
        onErrorToastMessage: 'Não foi possível remover a rede. Tente novamente.',
      },
    },
  },
  errorMessage: {
    accessDenied: {
      title: 'Hmm... Acesso negado!',
      subtitle: 'Parece que essa página não foi encontrada ou não existe.',
      buttonText: 'Home',
    },
  },
};

export default ptBr;
