import { createContext, useContext, useEffect, useState } from 'react';

interface ICustomer {
  name: string;
  email: string;
  id: string;
  countryCode: string;
  vendorId: string;
}

interface ICustomerContext {
  selectedCustomer?: ICustomer;
  onSelectCustomer: (customer: ICustomer) => void;
  onClearCustomer: () => void;
}

const CustomerContext = createContext({} as ICustomerContext);

const CustomerProvider = ({ children }) => {
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | undefined>(() => {
    const storedCustomer = localStorage.getItem('selectedCustomer');
    return storedCustomer ? JSON.parse(storedCustomer) : undefined;
  });

  /* istanbul ignore next */
  const onSelectCustomer = (customer: ICustomer) => {
    setSelectedCustomer(customer);
    localStorage.setItem('selectedCustomer', JSON.stringify(customer));
  };

  const onClearCustomer = () => {
    setSelectedCustomer(undefined);
    localStorage.removeItem('selectedCustomer');
  };

  useEffect(() => {
    const storedCustomer = localStorage.getItem('selectedCustomer');
    if (storedCustomer) {
      setSelectedCustomer(JSON.parse(storedCustomer));
    }
  }, []);

  return (
    <CustomerContext.Provider value={{ selectedCustomer, onSelectCustomer, onClearCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  return useContext(CustomerContext);
};

export { CustomerContext, CustomerProvider };
