export const enUS = {
  breadcrumb: {
    informationCenter: {
      title: 'Information center',
    },
    customerList: {
      title: 'Customer list',
    },
    chainManagement: {
      title: 'Chain management',
    },
    kamList: {
      title: "KAM's customer list",
    },
    kamCustomerList: {
      title: "KAM's customer list",
    },
    kamCustomerAddChain: {
      title: 'Add chains',
    },
  },
  pages: {
    customerList: {
      title: 'Which list would you like to manage?',
      featureCards: {
        kam: {
          title: 'KAM',
          description: "Change KAM's customer catalog",
        },
      },
    },
    kamList: {
      title: "KAM's Customer list",
      subtitle: "Manage KAM's customers list",
      selectKAM: 'Select a KAM',
      searchPlaceholder: 'Search users by name or e-mail',
      employeeEmail: 'Employee email',
      oneResultFound: ' result found',
      resultsFound: ' results found',
      loadingTitle: 'Loading',
      loadingText: "We're almost there...",
    },
    kamCustomerList: {
      title: "KAM's customers list",
      employeeEmail: 'Employee email',
      employeeIdDescription: 'Employee ID',
      chainTable: {
        title: 'Associated chains',
        chainName: 'Chain name',
        rowsPerPage: 'Items per page',
        paginationCounter: 'of',
        loadingMessage: 'Loading chains...',
        emptyMessage: 'No chain added...',
        btnAddChain: 'Add chain',
        searchPlaceHolder: 'Search by chain name',
        deleteButtonTooltip: 'Delete chain',
      },
      chainAddChainList: {
        title: 'Add chains',
        table: {
          name: 'Name',
          regions: 'Regions',
          all: 'All',
          id: 'ID',
          rowsPerPage: 'Items per page',
          paginationCounter: 'of',
          loadingMessage: 'Loading chains...',
          emptyMessage: 'Chain not found',
          searchPlaceHolder: 'Search by name',
          buttonAdd: 'Add',
        },
        btnContinue: 'Continue',
        btnCancel: 'Cancel',
        chainSelected: 'chain selected.',
        chainSelectedMultiple: 'chains selected.',
        stepsDisplay: {
          step1: 'Select chains',
          step2: 'Select regions',
        },
        onSuccessToastMessage: 'Chain added successfully',
        onSuccessToastMessageMultiple: 'Chains added successfully',
        onErrorToastMessage: 'Unable to add chain. Try again.',
      },
      chainAddRegionsList: {
        regionSelectedSingle: 'region selected',
        regionSelectedMultiple: 'regions selected',
        for: 'for',
        chainSingle: 'chain.',
        chainMultiple: 'chains.',
        onSuccessToastMessageSingle: 'region successfully added for',
        onSuccessToastMessageMultiple: 'regions successfully added for',
        onErrorToastMessage: 'Unable to add chain. Try again.',
      },
      chainDeleteChain: {
        title: "Are you sure you want to remove this chain from {name}'s list?",
        nameToRemove: 'Name: {name}',
        btnContinue: 'Remove',
        btnCancel: 'Cancel',
        onSuccessToastMessage: 'Chain removed successfully',
        onErrorToastMessage: 'Unable to remove chain. Try again.',
      },
    },
  },
  errorMessage: {
    accessDenied: {
      title: 'Hmm... Access denied!',
      subtitle: "It looks like you don't have permission to access this page.",
      buttonText: 'Home',
    },
  },
};

export default enUS;
