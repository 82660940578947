import { useNavigate } from 'react-router-dom';
import { kamListPath } from 'routes/Definition';
import { customerListProfileSelected } from '../../../analytics';
import { IFeatureCardProps } from './FeatureCard.props';
import { StyledCard, StyledDescription, StyledIcon, StyledTitle } from './FeatureCard.styles';

export const FeatureCard = ({
  icon,
  title,
  description,
  path,
  testId,
  segment,
}: IFeatureCardProps): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path === kamListPath) {
      customerListProfileSelected({
        screen_name: `${segment?.screen}`,
        list_type: `${segment?.listType}`,
      });
    }
    navigate(path);
  };
  return (
    <StyledCard border="medium" elevated="medium" onClick={handleClick} data-testid={testId}>
      <StyledIcon>{icon}</StyledIcon>
      <StyledTitle size="H4">{title}</StyledTitle>
      <StyledDescription size="xsmall">{description}</StyledDescription>
    </StyledCard>
  );
};
