import { Card, Heading, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const StyledCard = styled(Card, {
  width: '267px',
  height: '176px',
  padding: '$4',
  marginInline: '$2',
  cursor: 'pointer',
  boxShadow: 'rgba(0,0,0,0.16) 0px 0px 0px 1px !important',
  transition: 'box-shadow 200ms ease-in 0s',
  '&:hover': {
    boxShadow: 'rgba(0,0,0,0.16) 0px 4px 16px 0px !important',
  },
});

export const StyledIcon = styled('div', {
  width: '40px',
  height: '40px',
  backgroundColor: '$brandPrimaryBasis',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$full',
  marginBottom: '$2',
});

export const StyledTitle = styled(Heading, {
  marginBottom: '$1',
});

export const StyledDescription = styled(Paragraph, {
  marginBottom: '$1',
});
