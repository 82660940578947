import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { User } from '@hexa-ui/icons';

export const Container = styled('div', {
  width: '100%',
  height: '120px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '$6 $8',
});

export const Image = styled('img', {
  width: '72px',
  height: '72px',
  marginRight: '$6',
});

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '$2 0',
});

export const CustomerName = styled(Paragraph, {
  fontSize: '$6',
  marginBottom: '$2',
});

export const CustomerDescriptionContainer = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

export const CustomerDescriptionText = styled(Paragraph, {
  fontSize: '$2',
});

export const UserIcon = styled(User, {
  color: '#757575',
  marginRight: '$1',
});
