import { MessageMap } from '../providers/i18nProvider/i18n.d';
import { enUS } from './languages/en-US';
import { ptBr } from './languages/pt-BR';

const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBr,
};

export default messages;
