import { Grid } from '@hexa-ui/components';
import { useDynamicSidebar } from '@hooks';
import { useCustomer } from '@providers';
import { getAppHeaderConfig } from '@utils';
import { useAppHeader } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as Styles from './PageWrapper.styles';
import { PageWrapperProps } from './PageWrapper.types';

export const PageWrapper = ({ children, pageName, breadcrumbItems }: PageWrapperProps) => {
  const [, setAppHeaderConfig] = useAppHeader();
  const { formatMessage } = useIntl();
  const { selectedCustomer } = useCustomer();

  useDynamicSidebar();

  useEffect(() => {
    const setBreadcrumb = async () => {
      const appHeaderConfig = await getAppHeaderConfig(pageName, formatMessage, selectedCustomer);

      /* istanbul ignore next */
      setAppHeaderConfig(appHeaderConfig);
    };
    setBreadcrumb().catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, setAppHeaderConfig, pageName, breadcrumbItems]);

  return (
    <Grid.Container sidebar type="fluid">
      <Styles.Content>{children}</Styles.Content>
    </Grid.Container>
  );
};
