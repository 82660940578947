import { Error403 } from '@hexa-ui/components';
import { useHasPermission } from 'admin-portal-shared-services';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { homePath } from 'routes/Definition';

interface Props {
  children: React.ReactElement;
  permission: string | string[];
  isEnabled?: boolean;
}

export const ProtectedRoute = ({ isEnabled = false, children, permission }: Props) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const hasUserPermission = useHasPermission(permission, 'OR');
  return isEnabled && hasUserPermission ? (
    <>{children}</>
  ) : (
    <div style={{ height: '85vh' }}>
      <Error403
        header={formatMessage({ id: 'errorMessage.accessDenied.title' })}
        description={formatMessage({ id: 'errorMessage.accessDenied.subtitle' })}
        buttonText={formatMessage({ id: 'errorMessage.accessDenied.buttonText' })}
        onButtonClick={() => navigate(`/${homePath}`)}
      />
    </div>
  );
};
