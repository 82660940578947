import { useQuery } from '@tanstack/react-query';
import { get } from '../../api';
import { TGetKAMResponse, TUseKAMs } from './KAMService.props';

export const useKAM = ({ term, appName, projection, country, enabled }: TUseKAMs) =>
  useQuery<TGetKAMResponse>({
    queryKey: ['kams', term, country],
    queryFn: ({ signal }) =>
      get({
        url: `api/link-customer-list-service/user?value=${term}&appName=${appName}&projection=${projection}`,
        config: {
          signal,
          headers: {
            country,
          },
        },
      }),
    enabled,
  });
