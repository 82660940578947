/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface AddChainStarted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the list type selected by the user to manage. Samples: KAM, GN, Merchandiser
	 */
	list_type: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ChainAddingCanceled {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
}
export interface ChainsItem {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
}
export interface ChainAddingConfirmed {
	/**
	 * The array of chains. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	chains: ChainsItem[] | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
	/**
	 * The number of chains added to the KAM's catalog.
	 */
	quantity_added: number | null
}
export interface ChainRemovingCanceled {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
}
export interface ChainRemovingConfirmed {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ChainRemovingStarted {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ContractsItem {
	/**
	 * The ID of the contracts related to the selected chain.
	 */
	contract_id: string | null
	/**
	 * The number of the contracts related to the selected chain.
	 */
	contract_number: string | null
}
export interface ChainSelected {
	/**
	 * The ID of the chain selected by the user.
	 */
	chain_id: string | null
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The array of contracts. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	contracts: ContractsItem[] | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
}
export interface ChainAdded {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
}
export interface ChainsListPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ChainsListViewed {
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ContractsManagementPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface CustomerListInitialPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface CustomerListProfileSelected {
	/**
	 * What is the list type selected by the user to manage. Samples: KAM, GN, Merchandiser
	 */
	list_type: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface CustomerListStarted {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface LeversItem {
	/**
	 * The type of the lever that is been shown. Samples: Sell-in, Trade, Sellout
	 */
	lever_category: string | null
	/**
	 * Communication errors description when a lever dashboard is not loaded due to some error.
	 */
	lever_failure_reason: string | null
	/**
	 * The ID of the lever.
	 */
	lever_id: string | null
	/**
	 * Yes or no.
	 */
	lever_load_success: boolean | null
	/**
	 * The name of the lever displayed on the screen.
	 */
	lever_name: string | null
}
export interface DashboardLoaded {
	/**
	 * The current date.
	 */
	date: string | null
	/**
	 * The array of levers. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	levers: LeversItem[] | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface HomePageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface KamSelected {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
}
export interface KaMsCustomersListPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface KaMsListPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface KpiInteraction {
	/**
	 * The ID of the chain selected by the user.
	 */
	chain_id: string | null
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The current date.
	 */
	date: string | null
	/**
	 * The category of the lever that the user interacted with. Samples: Sell-in, Trade, Sellout
	 */
	lever_category: string | null
	/**
	 * The ID of the lever that the user interacted with.
	 */
	lever_id: string | null
	/**
	 * The name of the lever that the user interacted with.
	 */
	lever_name: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface KpIsPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface MenuInteraction {
	/**
	 * The top-level menu category that the user interacted with. Samples: Management, Visibility
	 */
	menu_category: string | null
}
export interface PeriodInteraction {
	/**
	 * The selected month by the user on the month list field. E.g: January
	 */
	month: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The selected year by the user on the year list field. E.g: 2023
	 */
	year: string | null
}
export interface SearchChainsInteraction {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The text informed in the search field to make the search for a KAM.
	 */
	search_text: string | null
}
export interface SearchKamInteraction {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The text informed in the search field to make the search for a KAM.
	 */
	search_text: string | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef AddChainStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} list_type - What is the list type selected by the user to manage. Samples: KAM, GN, Merchandiser
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ChainAddingCanceled
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 */
/**
 * @typedef ChainsItem
 * @property {string | null} chain_name - The name of the chain selected by the user.
 */
/**
 * @typedef ChainAddingConfirmed
 * @property {ChainsItem[] | null} chains - The array of chains. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 * @property {number | null} quantity_added - The number of chains added to the KAM's catalog.
 */
/**
 * @typedef ChainRemovingCanceled
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 */
/**
 * @typedef ChainRemovingConfirmed
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ChainRemovingStarted
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ContractsItem
 * @property {string | null} contract_id - The ID of the contracts related to the selected chain.
 * @property {string | null} contract_number - The number of the contracts related to the selected chain.
 */
/**
 * @typedef ChainSelected
 * @property {string | null} chain_id - The ID of the chain selected by the user.
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {ContractsItem[] | null} contracts - The array of contracts. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 */
/**
 * @typedef ChainAdded
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 */
/**
 * @typedef ChainsListPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ChainsListViewed
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ContractsManagementPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef CustomerListInitialPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef CustomerListProfileSelected
 * @property {string | null} list_type - What is the list type selected by the user to manage. Samples: KAM, GN, Merchandiser
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef CustomerListStarted
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef LeversItem
 * @property {string | null} lever_category - The type of the lever that is been shown. Samples: Sell-in, Trade, Sellout
 * @property {string | null} lever_failure_reason - Communication errors description when a lever dashboard is not loaded due to some error.
 * @property {string | null} lever_id - The ID of the lever.
 * @property {boolean | null} lever_load_success - Yes or no.
 * @property {string | null} lever_name - The name of the lever displayed on the screen.
 */
/**
 * @typedef DashboardLoaded
 * @property {string | null} date - The current date.
 * @property {LeversItem[] | null} levers - The array of levers. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef HomePageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef KamSelected
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 */
/**
 * @typedef KaMsCustomersListPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef KaMsListPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef KpiInteraction
 * @property {string | null} chain_id - The ID of the chain selected by the user.
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} date - The current date.
 * @property {string | null} lever_category - The category of the lever that the user interacted with. Samples: Sell-in, Trade, Sellout
 * @property {string | null} lever_id - The ID of the lever that the user interacted with.
 * @property {string | null} lever_name - The name of the lever that the user interacted with.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef KpIsPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef MenuInteraction
 * @property {string | null} menu_category - The top-level menu category that the user interacted with. Samples: Management, Visibility
 */
/**
 * @typedef PeriodInteraction
 * @property {string | null} month - The selected month by the user on the month list field. E.g: January
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} year - The selected year by the user on the year list field. E.g: 2023
 */
/**
 * @typedef SearchChainsInteraction
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} search_text - The text informed in the search field to make the search for a KAM.
 */
/**
 * @typedef SearchKamInteraction
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} search_text - The text informed in the search field to make the search for a KAM.
 */

/**
 * Triggers when the user clicks on the add chain button to manage the customer list of a KAM.



 *
 * @param {AddChainStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addChainStarted(
	props?: AddChainStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Chain Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user cancels the action of adding chains to the catalog.


 *
 * @param {ChainAddingCanceled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainAddingCanceled(
	props?: ChainAddingCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Adding Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user confirms the addition of chain to the KAM's catalog.






 *
 * @param {ChainAddingConfirmed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainAddingConfirmed(
	props?: ChainAddingConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Adding Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user cancels the action of removing a chain from the KAM's catalog.


 *
 * @param {ChainRemovingCanceled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainRemovingCanceled(
	props?: ChainRemovingCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Removing Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user confirms the removal of a chain from the KAM's catalog.




 *
 * @param {ChainRemovingConfirmed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainRemovingConfirmed(
	props?: ChainRemovingConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Removing Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the remove button of a chain added in the KAM's catalog.




 *
 * @param {ChainRemovingStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainRemovingStarted(
	props?: ChainRemovingStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Removing Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects a contract to see its KPIs page.
 *
 * @param {ChainSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainSelected(
	props?: ChainSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects a chain to be added to the KAMs catalog.

 *
 * @param {ChainAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainAdded(
	props?: ChainAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers whenever the user sees the Chains list page.


 *
 * @param {ChainsListPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainsListPageViewed(
	props?: ChainsListPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chains List Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees a list of chains.

 *
 * @param {ChainsListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainsListViewed(
	props: ChainsListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chains List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers whenever the user sees the Contracts management page.
 *
 * @param {ContractsManagementPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contractsManagementPageViewed(
	props?: ContractsManagementPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Contracts Management Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the initial page of the Customers List feature.


 *
 * @param {CustomerListInitialPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerListInitialPageViewed(
	props?: CustomerListInitialPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customer List Initial Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects a user profile on the customer list feature.

 *
 * @param {CustomerListProfileSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerListProfileSelected(
	props?: CustomerListProfileSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customer List Profile Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects the customers list app on the homepage.


 *
 * @param {CustomerListStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerListStarted(
	props?: CustomerListStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customer List Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the KPIs finish loading.
 *
 * @param {DashboardLoaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dashboardLoaded(
	props?: DashboardLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Dashboard Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the Commercial Agreements app's home page.


 *
 * @param {HomePageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function homePageViewed(
	props?: HomePageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Home Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects a KAM to manage his customers list.


 *
 * @param {KamSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kamSelected(
	props?: KamSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KAM Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the users sees the managing page of a KAM's list of customers.




 *
 * @param {KaMsCustomersListPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kaMsCustomersListPageViewed(
	props?: KaMsCustomersListPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KAMs Customers List Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the KAM's customers list page.


 *
 * @param {KaMsListPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kaMsListPageViewed(
	props?: KaMsListPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KAMs List Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user interacts with the dashboards on the KPIs page.
 *
 * @param {KpiInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpiInteraction(
	props?: KpiInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KPI Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees a contract's KPIs page.
 *
 * @param {KpIsPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpIsPageViewed(
	props?: KpIsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KPIs Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user interacts with the menus.
 *
 * @param {MenuInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteraction(
	props?: MenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user interacts with the period tabs on the KPIs page.
 *
 * @param {PeriodInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function periodInteraction(
	props?: PeriodInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Period Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user makes a search for chains.



 *
 * @param {SearchChainsInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchChainsInteraction(
	props?: SearchChainsInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Chains Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user makes a search for KAMs.



 *
 * @param {SearchKamInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchKamInteraction(
	props?: SearchKamInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search KAM Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Triggers when the user clicks on the add chain button to manage the customer list of a KAM.
	
	
	
	 *
	 * @param {AddChainStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addChainStarted,
	/**
	 * Triggers when the user cancels the action of adding chains to the catalog.
	
	
	 *
	 * @param {ChainAddingCanceled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainAddingCanceled,
	/**
	 * Triggers when the user confirms the addition of chain to the KAM's catalog.
	
	
	
	
	
	
	 *
	 * @param {ChainAddingConfirmed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainAddingConfirmed,
	/**
	 * Triggers when the user cancels the action of removing a chain from the KAM's catalog.
	
	
	 *
	 * @param {ChainRemovingCanceled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainRemovingCanceled,
	/**
	 * Triggers when the user confirms the removal of a chain from the KAM's catalog.
	
	
	
	
	 *
	 * @param {ChainRemovingConfirmed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainRemovingConfirmed,
	/**
	 * Triggers when the user clicks on the remove button of a chain added in the KAM's catalog.
	
	
	
	
	 *
	 * @param {ChainRemovingStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainRemovingStarted,
	/**
	 * Triggers when the user selects a contract to see its KPIs page.
	 *
	 * @param {ChainSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainSelected,
	/**
	 * Triggers when the user selects a chain to be added to the KAMs catalog.
	
	 *
	 * @param {ChainAdded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainAdded,
	/**
	 * Triggers whenever the user sees the Chains list page.
	
	
	 *
	 * @param {ChainsListPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainsListPageViewed,
	/**
	 * Triggers when the user sees a list of chains.
	
	 *
	 * @param {ChainsListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainsListViewed,
	/**
	 * Triggers whenever the user sees the Contracts management page.
	 *
	 * @param {ContractsManagementPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contractsManagementPageViewed,
	/**
	 * Triggers when the user sees the initial page of the Customers List feature.
	
	
	 *
	 * @param {CustomerListInitialPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerListInitialPageViewed,
	/**
	 * Triggers when the user selects a user profile on the customer list feature.
	
	 *
	 * @param {CustomerListProfileSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerListProfileSelected,
	/**
	 * Triggers when the user selects the customers list app on the homepage.
	
	
	 *
	 * @param {CustomerListStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerListStarted,
	/**
	 * Triggers when the KPIs finish loading.
	 *
	 * @param {DashboardLoaded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dashboardLoaded,
	/**
	 * Triggers when the user sees the Commercial Agreements app's home page.
	
	
	 *
	 * @param {HomePageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	homePageViewed,
	/**
	 * Triggers when the user selects a KAM to manage his customers list.
	
	
	 *
	 * @param {KamSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kamSelected,
	/**
	 * Triggers when the users sees the managing page of a KAM's list of customers.
	
	
	
	
	 *
	 * @param {KaMsCustomersListPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kaMsCustomersListPageViewed,
	/**
	 * Triggers when the user sees the KAM's customers list page.
	
	
	 *
	 * @param {KaMsListPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kaMsListPageViewed,
	/**
	 * Triggers when the user interacts with the dashboards on the KPIs page.
	 *
	 * @param {KpiInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpiInteraction,
	/**
	 * Triggers when the user sees a contract's KPIs page.
	 *
	 * @param {KpIsPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpIsPageViewed,
	/**
	 * Triggers when the user interacts with the menus.
	 *
	 * @param {MenuInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteraction,
	/**
	 * Triggers when the user interacts with the period tabs on the KPIs page.
	 *
	 * @param {PeriodInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	periodInteraction,
	/**
	 * Triggers when the user makes a search for chains.
	
	
	
	 *
	 * @param {SearchChainsInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchChainsInteraction,
	/**
	 * Triggers when the user makes a search for KAMs.
	
	
	
	 *
	 * @param {SearchKamInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchKamInteraction,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2MQFSM0dqzp0Zhef9Urrr3RZ842`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
